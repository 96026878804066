import React, { useState } from "react";
import "../App.css";
import Logo from "../assets/images/Logo.png";
import { Link } from "react-router-dom";



const Header = () => {
  let path = window.location.href.split('/');
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];

  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onMenuButtonClick = () => {
    toggleMenu();
  };

  const onItemClick = () => {
    closeMenu();
  };

    
  return (
    <header>
      <div className={`container-fluid ${isOpen ? 'menu-open' : ''}`}>
      <nav className="navbar navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/">
          <img src={Logo} alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={onMenuButtonClick}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="Menu">
          <ul className="navbar-nav">
            <li className={pathMatch === '/' ? 'active' : ''}>
              <Link className="nav-link" to="/" onClick={onItemClick}>
                Home
              </Link>
            </li>
            <li className={pathMatch === 'about-us' ? 'active' : ''}>
              <Link className="nav-link" to="/about-us" onClick={onItemClick}>
                About Us
              </Link>
            </li>
            <li className={pathMatch === 'news' ? 'active' : ''}>
              <Link className="nav-link" to="/news" onClick={onItemClick}>
                News
              </Link>
            </li>
            <li className={pathMatch === 'video' ? 'active' : ''}>
              <Link className="nav-link" to="/video" onClick={onItemClick}>
                Videos
              </Link>
            </li>
            <li className={pathMatch === 'ride' ? 'active' : ''}>
              <Link className="nav-link" to="/ride" onClick={onItemClick}>
               Ride Now
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    </header>
  );
};

export default Header;
