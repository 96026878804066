import { combineReducers } from "redux";

import rideReducer from './reducer/rideReducer'
import newsReducer from './reducer/newsReducer'










const rootReducer=combineReducers({
    rideReducer,
    newsReducer,

})
    


export default rootReducer;