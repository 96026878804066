

export const cityDropdownConstant ={
    CITY_DROP_DOWN_INITIATE :"CITY_DROP_DOWN_INITIATE",
    CITY_DROP_DOWN_SUCCESS  :"CITY_DROP_DOWN_SUCCESS",
    CITY_DROP_DOWN_FAILURE  :"CITY_DROP_DOWN_INITIATE",



}

export const rideConstant ={
    MODAL_NAME_INITIATE :"MODAL_NAME_INITIATE",
    MODAL_NAME_SUCCESS  :"MODAL_NAME_SUCCESS",
    MODAL_NAME_FAILURE  :"MODAL_NAME_INITIATE",

    MODAL_COLOUR_INITIATE :"MODAL_COLOUR_INITIATE",
    MODAL_COLOUR_SUCCESS  :"MODAL_COLOUR_SUCCESS",
    MODAL_COLOUR_FAILURE  :"MODAL_COLOUR_INITIATE",

    COUNTRY_LIST_INITIATE :"COUNTRY_LIST_INITIATE",
    COUNTRY_LIST_SUCCESS  :"COUNTRY_LIST_SUCCESS",
    COUNTRY_LIST_FAILURE  :"COUNTRY_LIST_INITIATE",


    BOOKIN_INITIATE :"BOOKING_INITIATE",
    BOOKIN_SUCCESS  :"BOOKING_SUCCESS",
    BOOKIN_FAILURE  :"BOOKING_INITIATE",






}





export const newsConstant ={
    NEWS_LIST_INITIATE :"NEWS_LIST_INITIATE",
    NEWS_LIST_SUCCESS  :"NEWS_LIST_SUCCESS",
    NEWS_LIST_FAILURE  :"NEWS_LIST_INITIATE",

    NEWS_INTR_LIST_INITIATE :"NEWS_INTR_LIST_INITIATE",
    NEWS_INTR_LIST_SUCCESS  :"NEWS_INTR_LIST_SUCCESS",
    NEWS_INTR_LIST_FAILURE  :"NEWS_INTR_LIST_INITIATE"
}
