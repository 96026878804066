import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import React, { useEffect } from "react";

import "./App.css";
import Dashboard from "./components/Dashboard";
import Ride from "./components/Ride";

import { ToastContainer, toast, Slide } from "react-toastify";
import News from "./components/News";
import Aboutus from "./components/Aboutus";
import Video from "./components/Video";

function App() { 

  
  useEffect(()=>{
    if (window.location.protocol === "http:") {
      console.log(
        "You are accessing this site via an insecure protocol (HTTP). " +
          "Redirecting you to HTTPS."
      );
      window.location.href = window.location.href.replace("http:", "https:");
    } else if (window.location.protocol === "https:") {
      console.log("You are accessing this site via our secure HTTPS protocol.");
    }
    
  },[])
  return (
    <React.Fragment>
      <ToastContainer />

      <HashRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/ride" element={<Ride />} />
          <Route path="/news" element={<News />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/video" element={<Video />} />
        </Routes>
      </HashRouter>
    </React.Fragment>
  );
}

export default App;
