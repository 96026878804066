import { rideConstant,cityDropdownConstant } from "../actionTypes";

const initialState = {
  modelList: [],
  colorList: [],
  cityList:[],
  countryList:[],
  bookingData:{},
  loader: false,
  error: {},
};

export default function rideReducer(state = initialState, { type, payload }) {
  switch (type) {
    case rideConstant.MODAL_NAME_INITIATE:
      return { ...state, loader: true, modelList: [] };
    case rideConstant.MODAL_NAME_SUCCESS:
      return { ...state, loader: false, modelList: payload.data };
      case rideConstant.MODAL_NAME_FAILURE:
        return { ...state, error: payload };
 

    case cityDropdownConstant.CITY_DROP_DOWN_INITIATE:
      return { ...state, cityList: [] };
      case cityDropdownConstant.CITY_DROP_DOWN_SUCCESS:
        return { ...state, loader: true,cityList: payload.data  };
      case cityDropdownConstant.CITY_DROP_DOWN_FAILURE:
        return { ...state, error:payload};
  

        case rideConstant.BOOKIN_INITIATE:
          return { ...state, bookingData: {} };
          case rideConstant.BOOKIN_SUCCESS:
            return { ...state, loader: true,bookingData: payload };
          case rideConstant.BOOKIN_FAILURE:
            return { ...state, error:payload};
      
    
 
  

    case rideConstant.MODAL_COLOUR_INITIATE:
      return { ...state, loader: true, colorList: [] };
    case rideConstant.MODAL_COLOUR_SUCCESS:
      return { ...state, loader: false, colorList: payload };

    case rideConstant.MODAL_COLOUR_FAILURE:
      return { ...state, error: payload };

    default:
      return state;
  }
}
