import axios from "axios";
import { baseUrl } from "../../config/config"
import { cityDropdownConstant } from "../actionTypes"



export const cityDropdownInitiate=() =>({
    type: cityDropdownConstant.CITY_DROP_DOWN_INITIATE

})

export const cityDropdownSuccess=(data) =>({
    type:cityDropdownConstant.CITY_DROP_DOWN_SUCCESS,
    payload:data

})

export const cityDropdownFailure=(data) =>({
    type:cityDropdownConstant.CITY_DROP_DOWN_FAILURE,
    payload : data

})




export const CityDropDownAction=(payload)=>{


    
        
    return dispatch => {
        dispatch(cityDropdownInitiate())

        return new Promise((resolve, reject) =>
            axios.post(`${baseUrl}/website/country-listing`,payload,)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(cityDropdownSuccess(data))

                    }
                    else {
                        dispatch(cityDropdownFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(cityDropdownFailure(err))
                    reject(err);
                })

        );
    }

}
