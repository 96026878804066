import React, { useEffect, useRef, useState} from 'react';
import Logo from "../assets/images/Logo.png";
import Shape1 from "../assets/images/Shape-1.png";
import Shape2 from "../assets/images/Shape-2.png";
import Shape3 from "../assets/images/Shape-3.png";
import Bike1 from "../assets/images/Bike-1.png";
import Bike2 from "../assets/images/Bike-2.png";
import Bike3 from "../assets/images/Bike-3.png";
import BikeQuestion from '../assets/images/BikeQuestion.png'
import imageSvg from "../assets/images/compress.svg"
import compress from "../assets/images/compress.svg";
import Expand from "../assets/images/Expand.png";
import videoexpand from "../assets/images/videoexpand.png";
import sport_video from "../assets/video/sport_video.MOV";
import v2_video from "../assets/video/v2_video.MOV";
import XR_video from "../assets/video/XR_video.MOV";
import video1 from "../assets/video/Bike1.mp4"
import Bike from "../assets/images/Bike.png"


import Footer from './Footer';
import Header from './Header';
import Modal from "react-bootstrap/Modal";


const Dashboard = () => {
  const [selectedTab, setSelectedTab] =useState('tab1')

  const [videoModal, setVideoModal] = useState(false);
  const handleVideoModalClose = () => setVideoModal(false);
  const handleVideoModalShow = (tab) => {  setSelectedTab(tab)
      setVideoModal(true);
  };


  const [tab1Modal, setTab1Modal] = useState(false);
  const handleTab1ModalShow = () => { if(tab1Modal){
    setTab1Modal(false)
    
  }else{setTab1Modal(true)}
  };

  const [tab2Modal, setTab2Modal] = useState(false);
  const handleTab2ModalShow = () => { if(tab2Modal){
    setTab2Modal(false)
    
  }else{setTab2Modal(true)}
  };

  const [tab3Modal, setTab3Modal] = useState(false);
  const handleTab3ModalShow = () => { if(tab3Modal){
    setTab3Modal(false)
    
  }else{setTab3Modal(true)}
  };

  const [tab4Modal, setTab4Modal] = useState(false);
  const handleTab4ModalShow = () => { if(tab4Modal){
    setTab4Modal(false)
    
  }else{setTab4Modal(true)}
  };


  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.autoplay = true; // Set autoplay to true
      video.muted = true; // Set muted to true
      video.loop = true; // Set loop to true
    }
  }, []);






  return (
    <div>
      <Header />
      <div className="SlideArea">
        <div className="SlideShape">
          <img src={Shape1} />
        </div>
        <div className="container-fluid">
          <div className="SlideHead">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#Tabs1">
                  Sport
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Tabs2">
                  V2
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Tabs3">
                  XR
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Tabs4">
                  KBike
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane active" id="Tabs1">
              <div className="SlideBox">
                <div className={tab1Modal?"SlideLeft ExpandWidth":"SlideLeft"}>
                  <figure>
                    <img src={Bike1} />
                  </figure>
                  <figcaption>
                    <span className="Line Line1" />
                    <span className="Circle Circle1" />
                  </figcaption>
                  <div className="ExpandBox">
                    <a onClick={handleTab1ModalShow} id="SportsHide">
                      <span className="Icon">
                        <img src={Expand}/>
                      </span>
                      <span className="Text">
                        <smap>Expand</smap>
                      </span>
                    </a>
                  </div>
                </div>
                <div className={tab1Modal?"SlideRight hide":"SlideRight"} id="SportsVideo">
                  <video autoPlay muted playsInline loop src={sport_video} ref={videoRef} />
{/*             
                  <article>


                    <h4>Electrified Riding Experience</h4>
                    <p>
                      Redefining the motorcycling experience, Hop OXO is powered
                      by state of the art 72 V “eFlow electric powertrain” for a
                      ride so smooth that you will feel like riding an air
                      carpet.
                    </p>
                  </article> */}
                  <div className="SlideRightOverlay">
                    <button
                      type="button"
                      onClick={(e)=>handleVideoModalShow('tab1')}
                    >
                      <figure>
                        <img src={videoexpand} />
                      </figure>
                    </button>
                  </div>
                </div>
              </div>
              <div className="SpecificationsArea">
                <div className="SpecificationsLeftShape">
                  <img src={Shape2} />
                </div>
                <div className="SpecificationsBox">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="Specifications">
                        <h4>Specifications</h4>
                        <p>
                          <label>Top Speed</label>
                          <span>90 Kmph</span>
                        </p>
                        <p>
                          <label>Range / Charge</label>
                          <span>110+ Km Eco mode 80 Km Normal</span>
                        </p>
                        <p>
                          <label>Acceleration</label>
                          <span>0 to 60 Kmph : 7.5 Seconds</span>
                        </p>
                        <p>
                          <label>Torque Nm</label>
                          <span>200+</span>
                        </p>
                        <p>
                          <label>Motor</label>
                          <span>5.0 kW Peak Power</span>
                        </p>
                        <p>
                          <label>Lithium Battery</label>
                          <span>3.65 kWh</span>
                        </p>
                        <p>
                          <label>Seating Capacity</label>
                          <span>2</span>
                        </p>
                        <p>
                          <label>Combi Brakes System</label>
                          <span>Disc/Disc 240mm/220mm</span>
                        </p>
                        <p>
                          <label>Front Suspension</label>
                          <span>Telescopic Hydraulic</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="Specifications SpecificationsRight">
                        <h4>Specifications</h4>
                        <p>
                          <label>Front Suspension</label>
                          <span>Telescopic, Dia 30mm</span>
                        </p>
                        <p>
                          <label>Rear Suspension</label>
                          <span>Twin Shock Absorber</span>
                        </p>
                        <p>
                          <label>Front Wheel</label>
                          <span>80/100 17 inches Tubeless</span>
                        </p>
                        <p>
                          <label>Rear Wheel</label>
                          <span>120/80 17 inches Tubeless</span>
                        </p>
                        <p>
                          <label>Odometer </label>
                          <span>Digital</span>
                        </p>
                        <p>
                          <label>GPS/App Connect</label>
                          <span>Optional</span>
                        </p>
                        <p>
                          <label>Headlight</label>
                          <span>Halogen/LED (12V – 35W)</span>
                        </p>
                        <p>
                          <label>Brake/Taillight</label>
                          <span>12V – 5/21W (Multi Reflector)</span>
                        </p>
                        <p>
                          <label>DRL</label>
                          <span>Yes</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SpecificationsRightShape">
                  <img src={Shape1} />
                </div>
              </div>
            </div>
            
            <div className="tab-pane fade" id="Tabs2">
              <div className="SlideBox">
              <div className={tab2Modal?"SlideLeft ExpandWidth":"SlideLeft"}>
                  <figure>
                    <img src={Bike3} />
                  </figure>
                  <figcaption>
                    <span className="Line Line3" />
                    <span className="Circle Circle3" />
                  </figcaption>
                  <div className="ExpandBox">
                    <a onClick={handleTab2ModalShow} id="XRHide">
                      <span className="Icon">
                        <img src={Expand}/>
                      </span>
                      <span className="Text">
                        <smap>Expand</smap>
                      </span>
                    </a>
                  </div>
                </div>
                <div className={tab2Modal?"SlideRight hide":"SlideRight"} id="SportsVideo">
                <video autoPlay muted playsInline loop src={v2_video} ref={videoRef} />
                  {/* <article>
                    <h4>Electrified Riding Experience</h4>
                    <p>
                      Redefining the motorcycling experience, Hop OXO is powered
                      by state of the art 72 V “eFlow electric powertrain” for a
                      ride so smooth that you will feel like riding an air
                      carpet.
                    </p>
                  </article> */}
                  <div className="SlideRightOverlay">
                    <button
                      type="button"
                      onClick={(e)=>handleVideoModalShow('tab2')}
                    >
                      <figure>
                        <img src={videoexpand} />
                      </figure>
                    </button>
                  </div>
                </div>
              </div>
              <div className="SpecificationsArea">
                <div className="SpecificationsLeftShape">
                  <img src={Shape2} />
                </div>
                <div className="SpecificationsBox">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="Specifications">
                        <h4>Specifications</h4>
                        <p>
                          <label>Top Speed</label>
                          <span>85 Kmph</span>
                        </p>

                        <p>
                          <label>Range / Charge</label>
                          <span>110+ Km Eco mode 80 Km Normal</span>
                        </p>
                        <p>
                          <label>Acceleration</label>
                          <span>0 to 60 Kmph : 7.5 Seconds</span>
                        </p>
                        <p>
                          <label>Torque Nm</label>
                          <span>200+</span>
                        </p>
                        <p>
                          <label>Motor</label>
                          <span>5.0 kW Peak Power</span>
                        </p>
                        <p>
                          <label>Lithium Battery</label>
                          <span>3.65 kWh</span>
                        </p>
                        <p>
                          <label>Seating Capacity</label>
                          <span>2</span>
                        </p>
                        <p>
                          <label>Combi Brakes System</label>
                          <span>Disc/Disc 240mm/220mm</span>
                        </p>
                        <p>
                          <label>Front Suspension</label>
                          <span>Telescopic Hydraulic</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="Specifications">
                        <h4>Specifications</h4>
                        <p>
                          <label>Front Suspension</label>
                          <span>Telescopic, Dia 30mm</span>
                        </p>
                        <p>
                          <label>Rear Suspension</label>
                          <span>Twin Shock Absorber</span>
                        </p>
                        <p>
                          <label>Front Wheel</label>
                          <span>80/100 17 inches Tubeless</span>
                        </p>
                        <p>
                          <label>Rear Wheel</label>
                          <span>120/80 17 inches Tubeless</span>
                        </p>
                        <p>
                          <label>Odometer </label>
                          <span>Digital</span>
                        </p>
                        <p>
                          <label>GPS/App Connect</label>
                          <span>Optional</span>
                        </p>
                        <p>
                          <label>Headlight</label>
                          <span>Halogen/LED (12V – 35W)</span>
                        </p>
                        <p>
                          <label>Brake/Taillight</label>
                          <span>12V – 5/21W (Multi Reflector)</span>
                        </p>
                        <p>
                          <label>DRL</label>
                          <span>Yes</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SpecificationsRightShape">
                  <img src={Shape3} />
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Tabs3">
              <div className="SlideBox">
              <div className={tab3Modal?"SlideLeft ExpandWidth":"SlideLeft"}>
                  <figure>
                    <img src={Bike2} />
                  </figure>
                  <figcaption>
                    <span className="Line Line2" />
                    <span className="Circle2" />
                  </figcaption>
                  <div className="ExpandBox">
                    <a onClick={handleTab3ModalShow} id="V2Hide">
                      <span className="Icon">
                        <img src={Expand} />
                      </span>
                      <span className="Text">
                        <smap>Expand</smap>
                      </span>
                    </a>
                  </div>
                </div>
                <div className={tab3Modal?"SlideRight hide":"SlideRight"}>
                <video autoPlay muted playsInline loop src={XR_video} ref={videoRef}/>
                  {/* <article>
                    <h4>Electrified Riding Experience</h4>
                    <p>
                      Redefining the motorcycling experience, Hop OXO is powered
                      by state of the art 72 V “eFlow electric powertrain” for a
                      ride so smooth that you will feel like riding an air
                      carpet.
                    </p>
                  </article> */}
                  <div className="SlideRightOverlay">
                    <button
                      type="button"
                      onClick={(e)=>handleVideoModalShow('tab3')}
                    >
                      <figure>
                        <img src={videoexpand} />
                      </figure>
                    </button>
                  </div>
                </div>
              </div>
              <div className="SpecificationsArea">
                <div className="SpecificationsLeftShape">
                  <img src={Shape2} />
                </div>
                <div className="SpecificationsBox">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="Specifications">
                        <h4>Specifications</h4>
                        <p>
                          <label>Top Speed</label>
                          <span>55 Kmph</span>
                        </p>
                        <p>
                          <label>Range / Charge</label>
                          <span>120+ Km Eco mode </span>
                        </p>
                        {/* <p>
                          <label>Acceleration</label>
                          <span>0 to 60 Kmph : 7.5 Seconds</span>
                        </p> */}
                        <p>
                          <label>Torque Nm</label>
                          <span>130+</span>
                        </p>
                        <p>
                          <label>Motor</label>
                          <span>2.5 kW Peak Power</span>
                        </p>
                        <p>
                          <label>Lithium Battery</label>
                          <span>3.0 kWh</span>
                        </p>
                        <p>
                          <label>Seating Capacity</label>
                          <span>2</span>
                        </p>
                        <p>
                          <label>Combi Brakes System</label>
                          <span>Drum/Drum</span>
                        </p>
                        <p>
                          <label>Front Suspension</label>
                          <span>Telescopic Hydraulic</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="Specifications">
                        <h4>Specifications</h4>
                        <p>
                          <label>Front Suspension</label>
                          <span>Telescopic, Dia 30mm</span>
                        </p>
                        <p>
                          <label>Rear Suspension</label>
                          <span>Twin Shock Absorber</span>
                        </p>
                        <p>
                          <label>Front Wheel</label>
                          <span> 17 inches </span>
                        </p>
                        <p>
                          <label>Rear Wheel</label>
                          <span>17 inches </span>
                        </p>
                        <p>
                          <label>Odometer </label>
                          <span>Digital</span>
                        </p>
                        <p>
                          <label>GPS/App Connect</label>
                          <span>Optional</span>
                        </p>
                        <p>
                          <label>Headlight</label>
                          <span>Halogen/LED (12V – 35W)</span>
                        </p>
                        <p>
                          <label>Brake/Taillight</label>
                          <span>12V – 5/21W (Multi Reflector)</span>
                        </p>
                        <p>
                          <label>DRL</label>
                          <span>Yes</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SpecificationsRightShape">
                  <img src={Shape3} />
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Tabs4">
              <div className="SlideBox">
              <div className={tab4Modal?"SlideLeft ExpandWidth":"SlideLeft"}>
                  <figure>
                    <img src={Bike} />
                  </figure>
                  <figcaption>
                    <span className="Line Line4" />
                    <span className="Circle Circle4" />
                  </figcaption>
                  <div className="ExpandBox">
                    <a onClick={handleTab4ModalShow} id="KBikeHide">
                      <span className="Icon">
                      <img src={Expand}/>
                      </span>
                      <span className="Text">
                        <smap>Expand</smap>
                      </span>
                    </a>
                  </div>
                </div>
                <div className={tab4Modal?"SlideRight hide":"SlideRight"} id="SportsVideo">
                <video autoPlay muted playsInline loop src={video1} ref={videoRef}/>
                  {/* <article>
                    <h4>Electrified Riding Experience</h4>
                    <p>
                      Redefining the motorcycling experience, Hop OXO is powered
                      by state of the art 72 V “eFlow electric powertrain” for a
                      ride so smooth that you will feel like riding an air
                      carpet.
                    </p>
                  </article>
                  */}
                  <div className="SlideRightOverlay">
                    <button
                      type="button"
                      onClick={(e)=>handleVideoModalShow('tab4')}
                    >
                      <figure>
                        <img src={videoexpand} />
                      </figure>
                    </button>
                  </div>
                </div>
              </div>
              <div className="SpecificationsArea">
                <div className="SpecificationsLeftShape">
                  <img src={Shape2} />
                </div>
                <div className="SpecificationsBox">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="Specifications">
                        <h4>Specifications</h4>
                        <p>
                          <label>Top Speed</label>
                          <span>25 Kmph</span>
                        </p>
                        <p>
                          <label>Range / Charge</label>
                          <span>80 Km Eco mode</span>
                        </p>
                        <p>
                          <label>Motor</label>
                          <span>250 W Peak Power</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="Specifications">
                        <h4>Specifications</h4>
                        <p>
                          <label>Lithium Battery</label>
                          <span>&lt; 1k kWh</span>
                        </p>
                        <p>
                          <label>Seating Capacity</label>
                          <span>1</span>
                        </p>
                        <p>
                          <label>Combi Brakes</label>
                          <span>Drum/Drum</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SpecificationsRightShape">
                  <img src={Shape3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
          show={videoModal}
          onHide={handleVideoModalClose}
          className="ModalBox VideoModal"
        >
          <Modal.Body>
            <div className="Decline">
              <div className="modal-body">
                    <button type="button" className="close CloseModal" onClick={handleVideoModalClose}>
                        <span aria-hidden="true">
                            <img src={imageSvg} />
                        </span>
                      </button>
                      <div className="SlideRight">
                      <video autoPlay muted playsInline loop ref={videoRef} src={selectedTab == "tab1" && sport_video || selectedTab =='tab2' && v2_video || selectedTab == 'tab3' && XR_video || selectedTab == 'tab4' && video1} />
                        {/* <article>
                            <h4>Electrified Riding Experience</h4>
                            <p>Redefining the motorcycling experience, Hop OXO is powered by state of the art 72 V “eFlow electric powertrain” for a ride so smooth  that you will feel like riding an air carpet.</p>
                        </article> */}
                    </div>


            </div>
            </div>
          </Modal.Body>
        </Modal>

      <Footer/>
    </div>
  );
}

export default Dashboard