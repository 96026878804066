import axios from "axios";
import { baseUrl } from "../../config/config"
import { newsConstant,  } from "../actionTypes"



export const newsInitiate=() =>({
    type: newsConstant.NEWS_LIST_INITIATE

})

export const newsSuccess=(data) =>({
    type:newsConstant.NEWS_LIST_SUCCESS,
    payload:data

})

export const newsFailure=(data) =>({
    type:newsConstant.NEWS_LIST_FAILURE,
    payload : data

})

export const newsAction=(payload)=>{
    return dispatch => {
        dispatch(newsInitiate())
        return new Promise((resolve, reject) =>
            axios.get(`${baseUrl}/website/news-list/INDIA`)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(newsSuccess(data))

                    }
                    else {
                        dispatch(newsFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(newsFailure(err))
                    reject(err);
                })

        );
    }

}


//==============================INTR



export const newsIntrInitiate=() =>({
    type: newsConstant.NEWS_INTR_LIST_INITIATE

})

export const newsIntrSuccess=(data) =>({
    type:newsConstant.NEWS_INTR_LIST_SUCCESS,
    payload:data

})

export const newsIntrFailure=(data) =>({
    type:newsConstant.NEWS_INTR_LIST_FAILURE,
    payload : data

})

export const newsIntrAction=(payload)=>{
    return dispatch => {
        dispatch(newsInitiate())
        return new Promise((resolve, reject) =>
            axios.get(`${baseUrl}/website/news-list/INTERNATIONAL`)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(newsIntrSuccess(data))

                    }
                    else {
                        dispatch(newsIntrFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(newsIntrFailure(err))
                    reject(err);
                })

        );
    }

}

