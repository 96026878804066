import { newsConstant } from "../actionTypes";

const initialState = {
  newsList: [],
  newsIntrList:[],
  loader: false,
  error: {},
};

export default function newsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case newsConstant.NEWS_LIST_INITIATE:
      return { ...state, loader: true, newsList: [] };
    case newsConstant.NEWS_LIST_SUCCESS:
      return { ...state, loader: false, newsList: payload.data };
      case newsConstant.NEWS_LIST_FAILURE:
        return { ...state, error: payload };
 

        case newsConstant.NEWS_INTR_LIST_INITIATE:
          return { ...state, loader: true, newsIntrList: [] };
        case newsConstant.NEWS_INTR_LIST_SUCCESS:
          return { ...state, loader: false, newsIntrList: payload.data };
          case newsConstant.NEWS_INTR_LIST_FAILURE:
            return { ...state, error: payload };


    default:
      return state;
  }
}
