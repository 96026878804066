import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { toast } from "react-toastify";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { CityDropDownAction } from "../redux/actions/cityDropdownAction";
import { BookingAction } from "../redux/actions/rideAction";

const initialState = {
  contryType: "INDIA",
  bookingType: "TEST",
  name: "",
  email: "",
  modelName: "",
  modelColour: "",
  country: "",
  city: "",
  phoneNumbe: "",
  bookingAmount: "",
  messege: "",
  errorMsg: "",
};

const distributorState = {
  nameDistributor: "",
  emailDistributor: "",
  cityDistributor: "",
  countryDistributor: "",
  phoneNumbeDistributor: "",
  messegeDistributor: "",
  companyName: "",
  showroom: "",
  experience: "",
  investmentPlan: "",
  errorMsgDistributor: {},
};
const Ride = () => {
  const dispatch = useDispatch();

  const [iState, updateState] = useState(_.cloneDeep(initialState));
  const [iDistributorState, updateDistributorState] =
    useState(distributorState);

  const {
    country,
    contryType,
    bookingType,
    name,
    email,
    modelName,
    modelColour,
    city,
    phoneNumbe,
    bookingAmount,
    messege,
    errorMsg,
  } = iState;
  const {
    companyName,
    nameDistributor,
    emailDistributor,
    cityDistributor,
    phoneNumbeDistributor,
    messegeDistributor,
    errorMsgDistributor,
    experience,
    showroom,
    investmentPlan,
    countryDistributor,
  } = iDistributorState;

  const { cityList, bookingData } = useSelector((state) => state.rideReducer);

  const handleValidationBooking = () => {
    let errorMsg = {};
    let formIsValid = true;
    if (!modelName) {
      errorMsg.modelName = "* Please select model name";
      formIsValid = false;
    }
    if (!modelColour) {
      errorMsg.modelColour = "* Please select model colour";
      formIsValid = false;
    }

    if (contryType == "INDIA") {
      if (!city) {
        errorMsg.city = "* Please select city";
        formIsValid = false;
      }
    }
    if (!country) {
      errorMsg.country = "* Please select country";
      formIsValid = false;
    }

    if (!email) {
      errorMsg.email = "* Please enter email.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      errorMsg.validEmail = "* Email format is not valid";
      formIsValid = false;
    }
    if (!phoneNumbe) {
      errorMsg.phoneNumbe = "* Please enter phone number";
      formIsValid = false;
    } else if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phoneNumbe)) {
      errorMsg.phoneValid = "* Please enter valid number";
      formIsValid = false;
    }

    if (!name) {
      errorMsg.name = "* Please enter name";
      formIsValid = false;
    } else {
      if (!/^[A-Za-z\s]+$/.test(name)) {
        errorMsg.name = "* Please enter only Alphabet";
        formIsValid = false;
      }
    }

    // else if (!/^[A-Za-z\s]+$/.test(name)) {
    //   errorMsg.name = " * Only alphabets are allowed";
    //   formIsValid = false;
    // }

    // if (!bookingAmount) {
    //   errorMsg.bookingAmount = "* Please enter booking amount";
    //   formIsValid = false;
    // }

    if (!messege) {
      errorMsg.messege = "* Please enter message";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errorMsg: errorMsg,
    });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleValidationDistributor = () => {
    let errorMsgDistributor = {};
    let formIsValidDistributor = true;
    if (!investmentPlan) {
      errorMsgDistributor.investmentPlan = "* Please select investment plan";
      formIsValidDistributor = false;
    }
    if (!experience) {
      errorMsgDistributor.experience = "* Please select experience";
      formIsValidDistributor = false;
    }

    if (!showroom && contryType == "INDIA") {
      errorMsgDistributor.showroom = "* Please select showroom";
      formIsValidDistributor = false;
    }

    if (contryType == "INDIA") {
      if (!cityDistributor) {
        errorMsgDistributor.city = "* Please select city";
        formIsValidDistributor = false;
      }
    }

    if (!emailDistributor) {
      errorMsgDistributor.email = "* Please enter email.";
      formIsValidDistributor = false;
    } else if (!/^.+?@.+?\..+$/.test(emailDistributor)) {
      errorMsgDistributor.validEmail = "* Email format is not valid";
      formIsValidDistributor = false;
    }
    if (!phoneNumbeDistributor && contryType === "INDIA") {
      errorMsgDistributor.phoneNumberDistributor =
        "* Please enter phone number";
      formIsValidDistributor = false;
    } else if (
      !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phoneNumbeDistributor) &&
      contryType === "INDIA"
    ) {
      errorMsgDistributor.phoneValid = "* Please enter valid number";
      formIsValidDistributor = false;
    }
    if (!companyName && contryType === "INTERNATIONAL") {
      errorMsgDistributor.companyName = "* Please enter company name";
      formIsValidDistributor = false;
    }

    if (!nameDistributor) {
      errorMsgDistributor.nameDistributor = "* Please enter name";
      formIsValidDistributor = false;
    } else {
      if (!/^[A-Za-z\s]+$/.test(nameDistributor)) {
        errorMsgDistributor.nameDistributor = "* Please enter only Alphabet";
        formIsValidDistributor = false;
      }
    }

    if (contryType == "INDIA"){
      if (!cityDistributor) {
        errorMsgDistributor.cityDistributor = "* Please select city";
        formIsValidDistributor = false;
      }
    }
   

    if (!messegeDistributor) {
      errorMsgDistributor.messegeDistributor = "* Please enter message";
      formIsValidDistributor = false;
    }
    if (!countryDistributor) {
      errorMsgDistributor.countryDistributor = "* Please select country";
      formIsValidDistributor = false;
    }

    updateDistributorState({
      ...iDistributorState,
      errorMsgDistributor: errorMsgDistributor,
    });
    return formIsValidDistributor;
  };

  const handleDistributorChange = (e) => {
    const { name, value } = e.target;

    updateDistributorState({
      ...iDistributorState,
      [name]: value,
    });
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked === true) {
      updateState({ ...iState, contryType: "INTERNATIONAL", country: "",errorMsg: ""});
      updateDistributorState({ ...iDistributorState, countryDistributor: "" ,errorMsgDistributor:"",});
    } else {
      updateState({ ...iState, contryType: "INDIA", country: "India" ,errorMsg:""});
      updateDistributorState({
        ...iDistributorState,
        countryDistributor: "India",
        errorMsgDistributor:""
      });
    }
  };

  useEffect(() => {
    dispatch(CityDropDownAction());
    if (contryType === "INDIA") {
      updateState({ ...iState, country: "India" });
      updateDistributorState({
        ...iDistributorState,
        countryDistributor: "India",
      });
    }
  }, []);

  const handleDisributorCheck = (e, type, val) => {
    if (type === "showroom") {
      updateDistributorState({ ...iDistributorState, showroom: val });
    } else if (type == "experience") {
      updateDistributorState({ ...iDistributorState, experience: val });
    } else {
      updateDistributorState({ ...iDistributorState, investmentPlan: val });
    }
  };

  const handlseSubmitBooking = (e) => {
    e.preventDefault();

    let formIsValid = handleValidationBooking();

    if (formIsValid) {
      var data = {
        countryType: contryType,
        bookingType,
        name,
        email,
        modelName,
        modelColour,
        cityId: contryType == "INDIA" ? city : country,
        phoneNumber: phoneNumbe,
        // bookingAmount,
        messege,
        countryId: contryType == "INDIA" ? city : country,
      };

      dispatch(BookingAction(data))
        .then((res) => {
          if (res.status === 200) {
            toast.success("Booking Successful", {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              contryType:contryType == "INDIA" ? "INDIA":"INTERNATIONAL",
              bookingType: "TEST",
              name: "",
              email: "",
              modelName: "",
              modelColour: "",
              country: "",
              city: "",
              phoneNumbe: "",
              bookingAmount: "",
              messege: "",
              errorMsg: "",
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handlseSubmitDistributor = (e) => {
    e.preventDefault();

    let formIsValid = handleValidationDistributor();

    if (formIsValid) {
      if (contryType === "INDIA") {
        var data = {
          countryType: contryType,
          bookingType,
          name: nameDistributor,
          email: emailDistributor,
          cityId: cityDistributor,
          countryId: cityDistributor,
          phoneNumber: phoneNumbeDistributor,
          messege: messegeDistributor,
          showroom,
          experience,
          investmentPlan,
        };
      } else
        data = {
          countryType: contryType,
          bookingType,
          name: nameDistributor,
          email: emailDistributor,
           cityId: countryDistributor,
          countryId: countryDistributor,
          companyName,
          messege: messegeDistributor,

          experience,
          investmentPlan,
        };

      dispatch(BookingAction(data))
        .then((res) => {
          if (res.status === 200) {
            toast.success("Booking Successful", {
              position: toast.POSITION.TOP_RIGHT,
            });

            updateDistributorState({
              ...iDistributorState,
              nameDistributor: "",
              emailDistributor: "",
              cityDistributor: "",
              countryDistributor: "",
              phoneNumbeDistributor: "",
              messegeDistributor: "",
              companyName: "",
              showroom: "",
              experience: "",
              investmentPlan: "",
              errorMsgDistributor: {},
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <div>
      <Header />
      <section>
        <div className="Breadcumb BreadcumbRide BreadcumbBg">
          <article >
            <h4>Become a partner</h4>
            <p>
              Inviting Distributors, Last Mile Delivery Companies and Bike Taxi
              Operators who are focussed on Quality Electric Two Wheelers with
              swappable batteries. 
            </p>
            <p><b>India & Africa :</b> Inviting Partners for mass
              deployment of Kridn V2 and Kridn XR.</p>
              <p><b>Europe , South East Asia &
              Americas :</b> Inviting Distribution partners to start testing and
              validation of all models.</p>
          </article>
        </div>
      </section>
      <section>
        <div className="PartnerArea">
          <div className="PartnerLeftShape">
            <img src="images/Shape-2.png" />
          </div>
          <div className="container-fluid">
            <div className="PartnerHead">
              <ol>
                <li>India</li>
                <li>
                  <label className="Switch">
                    <input type="checkbox" onChange={onChangeCheckbox} />
                    <span className="slider" />
                  </label>
                </li>
                <li>International</li>
              </ol>
            </div>
            <div className="PartnerBody" id="India">
              <article>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#Tabs1"
                      onClick={() =>
                        updateState({ ...iState, bookingType: "TEST" })
                      }
                    >
                      Booking / Test Ride Interest
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#Tabs2"
                      onClick={() =>
                        updateState({ ...iState, bookingType: "DISTRIBUTOR" })
                      }
                    >
                      B2B Partner / Distributor
                    </a>
                  </li>
                </ul>
              </article>
              <div className="tab-content">
                <div className="tab-pane active" id="Tabs1">
                  <div className="CommonForm">
                    <div className="row">
                      <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Model Name</label>
                              <select
                                className="form-control"
                                value={modelName}
                                name="modelName"
                                onChange={handleChange}
                              >
                                <option value="">Select Model Name</option>
                                <option value="Sports">Sport</option>
                                <option value="V2">V2</option>
                                <option value="XR">XR</option>
                                <option value="KBike">KBike</option>
                                
                              </select>
                              {errorMsg.modelName && !modelName && (
                                <p style={{ color: "red" }}>
                                  {errorMsg.modelName}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Model colour</label>
                              <select
                                value={modelColour}
                                name="modelColour"
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option>Select Model colour</option>
                                <option value="Black">Black</option>
                                <option value="Blue">Blue</option>
                                <option value="Red">Red</option>
                              </select>
                              {errorMsg.modelColour && !modelColour && (
                                <p style={{ color: "red" }}>
                                  {errorMsg.modelColour}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Full Name</label>
                              <input
                                value={name}
                                name="name"
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter Full Name"
                              />
                              {errorMsg.name && (
                                <p style={{ color: "red" }}>{errorMsg.name}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email id</label>
                              <input
                                value={email}
                                name="email"
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter Email id"
                              />
                              {errorMsg.email && !email && (
                                <p style={{ color: "red" }}>{errorMsg.email}</p>
                              )}
                              {errorMsg.validEmail && (
                                <p style={{ color: "red" }}>
                                  {errorMsg.validEmail}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Phone Number</label>
                              <input
                                value={phoneNumbe}
                                name="phoneNumbe"
                                onChange={handleChange}
                                type="number"
                                className="form-control"
                                placeholder="Enter Phone Number"
                              />
                              {errorMsg.phoneNumbe && !phoneNumbe && (
                                <p style={{ color: "red" }}>
                                  {errorMsg.phoneNumbe}
                                </p>
                              )}

                              {errorMsg.phoneValid && (
                                <p style={{ color: "red" }}>
                                  {errorMsg.phoneValid}
                                </p>
                              )}
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group">
                              <label>Booking Amount</label>
                              <input
                                value={bookingAmount}
                                name="bookingAmount"
                                disabled="true"
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter Booking Amount"
                              />
                              {errorMsg.bookingAmount && !bookingAmount && (
                                <p style={{ color: "red" }}>
                                  {errorMsg.bookingAmount}
                                </p>
                              )}
                            </div>
                          </div> */}
                          {contryType === "INTERNATIONAL" && (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Country</label>
                                <select
                                  onChange={handleChange}
                                  value={country}
                                  name="country"
                                  className="form-control"
                                >
                                  <option value="">Select Country</option>
                                  {contryType === "INDIA" ? (
                                    <option value="India">India</option>
                                  ) : (
                                    cityList
                                      ?.filter(
                                        (ele) => ele?.countryName !== "India"
                                      )
                                      .map((item) => (
                                        <option value={item._id}>
                                          {item?.countryName}
                                        </option>
                                      ))
                                  )}
                                </select>

                                {errorMsg.country && !country && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.country}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                          {contryType == "INDIA" && (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>City</label>
                                <select
                                  className="form-control"
                                  value={city}
                                  name="city"
                                  onChange={handleChange}
                                >
                                  <option>Select City</option>
                                  {cityList
                                    ?.filter(
                                      (item) => item?.countryName === "India"
                                    )
                                    ?.map((ele) => (
                                      <option value={ele?._id}>
                                        {ele?.cityName}
                                      </option>
                                    ))}
                                </select>
                                {errorMsg.city && !city && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.city}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Message </label>
                              <input
                                value={messege}
                                name="messege"
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter ......"
                              />
                              {errorMsg.messege && !messege && (
                                <p style={{ color: "red" }}>
                                  {errorMsg.messege}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button
                              type="button"
                              onClick={handlseSubmitBooking}
                            >
                              Register Interest
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="Tabs2">
                  <div className="CommonForm">
                    <div className="row">
                      <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Full Name</label>
                              <input
                                value={nameDistributor}
                                name="nameDistributor"
                                onChange={handleDistributorChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter Full Name"
                              />
                              {errorMsgDistributor.nameDistributor && (
                                <p style={{ color: "red" }}>
                                  {errorMsgDistributor.nameDistributor}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email id</label>
                              <input
                                type="email"
                                value={emailDistributor}
                                name="emailDistributor"
                                onChange={handleDistributorChange}
                                className="form-control"
                                placeholder="Enter Email id"
                              />
                              {errorMsgDistributor.email &&
                                !emailDistributor && (
                                  <p style={{ color: "red" }}>
                                    {errorMsgDistributor.email}
                                  </p>
                                )}
                              {errorMsgDistributor.validEmail && (
                                <p style={{ color: "red" }}>
                                  {errorMsgDistributor.validEmail}
                                </p>
                              )}
                            </div>
                          </div>
                          {contryType === "INTERNATIONAL" && (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Country</label>
                                <select
                                  onChange={handleDistributorChange}
                                  value={countryDistributor}
                                  name="countryDistributor"
                                  className="form-control"
                                >
                                  <option value="">Select Country</option>
                                  {contryType === "INDIA" ? (
                                    <option value="India">India</option>
                                  ) : (
                                    cityList
                                      ?.filter(
                                        (ele) => ele?.countryName !== "India"
                                      )
                                      .map((item) => (
                                        <option value={item?._id}>
                                          {item?.countryName}
                                        </option>
                                      ))
                                  )}
                                </select>

                                {errorMsgDistributor.countryDistributor &&
                                  !countryDistributor && (
                                    <p style={{ color: "red" }}>
                                      {errorMsgDistributor.countryDistributor}
                                    </p>
                                  )}
                              </div>
                            </div>
                          )}
                          {contryType === "INDIA" && (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>City</label>
                                <select
                                  value={cityDistributor}
                                  onChange={handleDistributorChange}
                                  name="cityDistributor"
                                  className="form-control"
                                >
                                  <option>Select City</option>
                                  {cityList
                                    ?.filter(
                                      (el) => el?.countryName === "India"
                                    )
                                    ?.map((item) => (
                                      <option value={item?._id}>
                                        {item?.cityName}
                                      </option>
                                    ))}
                                </select>
                                {errorMsgDistributor.cityDistributor &&
                                  !cityDistributor && (
                                    <p style={{ color: "red" }}>
                                      {errorMsgDistributor.cityDistributor}
                                    </p>
                                  )}
                              </div>
                            </div>
                          )}

                          {contryType === "INDIA" ? (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Phone Number</label>
                                <input
                                  type="number"
                                  value={phoneNumbeDistributor}
                                  name="phoneNumbeDistributor"
                                  onChange={handleDistributorChange}
                                  className="form-control"
                                  placeholder="Enter Phone Number"
                                />
                                {errorMsgDistributor.phoneNumberDistributor &&
                                  !phoneNumbeDistributor && (
                                    <p style={{ color: "red" }}>
                                      {
                                        errorMsgDistributor.phoneNumberDistributor
                                      }
                                    </p>
                                  )}
                                {errorMsgDistributor.phoneValid && (
                                  <p style={{ color: "red" }}>
                                    {errorMsgDistributor.phoneValid}
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Company Name</label>
                                <input
                                  type="text"
                                  value={companyName}
                                  name="companyName"
                                  onChange={handleDistributorChange}
                                  className="form-control"
                                  placeholder="Enter Company Name"
                                />
                                {errorMsgDistributor.companyName &&
                                  !companyName && (
                                    <p style={{ color: "red" }}>
                                      {errorMsgDistributor.companyName}
                                    </p>
                                  )}
                              </div>
                            </div>
                          )}

                          {contryType === "INDIA" && (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Do you own a showroom </label>
                                <ul>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      Yes
                                      <input
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "showroom",
                                            "yes"
                                          )
                                        }
                                        name="showroom"
                                        type="radio"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      No
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "showroom",
                                            "no"
                                          )
                                        }
                                        name="showroom"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  {errorMsgDistributor.showroom &&
                                    !showroom && (
                                      <p style={{ color: "red" }}>
                                        {errorMsgDistributor.showroom}
                                      </p>
                                    )}
                                </ul>
                              </div>
                            </div>
                          )}
                          {contryType === "INTERNATIONAL" ? (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Investment plan</label>
                                <ul className="RadioWrap">
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      Under 1 M
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "investmentPlan",
                                            "Under 1 M"
                                          )
                                        }
                                        name="internationalPlan"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      1 - 5 M
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "investmentPlan",
                                            "1 - 5 M"
                                          )
                                        }
                                        name="internationalPlan"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      5 M +
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "investmentPlan",
                                            "5 M +"
                                          )
                                        }
                                        name="internationalPlan"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  {errorMsgDistributor.investmentPlan &&
                                    !investmentPlan && (
                                      <p style={{ color: "red" }}>
                                        {errorMsgDistributor.investmentPlan}
                                      </p>
                                    )}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Investment plan</label>
                                <ul>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      Under 1 Cr
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "investmentPlan",
                                            "Under 1 Cr"
                                          )
                                        }
                                        name="investmentPlan"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      1 - 5 Cr
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "investmentPlan",
                                            "1 - 5 Cr"
                                          )
                                        }
                                        name="investmentPlan"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      5 Cr +
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "investmentPlan",
                                            "5 Cr +"
                                          )
                                        }
                                        name="investmentPlan"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  {errorMsgDistributor.investmentPlan &&
                                    !investmentPlan && (
                                      <p style={{ color: "red" }}>
                                        {errorMsgDistributor.investmentPlan}
                                      </p>
                                    )}
                                </ul>
                              </div>
                            </div>
                          )}
                          {contryType === "INTERNATIONAL" ? (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>
                                  Experience in EV or Automobile Industry
                                </label>
                                <ul>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      0 Years
                                      <input
                                        value={experience}
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "experience",
                                            "0 Years"
                                          )
                                        }
                                        name="internationalExperience"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      0-5 Years
                                      <input
                                        value={experience}
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "experience",
                                            "0-5 Years"
                                          )
                                        }
                                        name="internationalExperience"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      5+ Years
                                      <input
                                        value={experience}
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "experience",
                                            "5+ Years"
                                          )
                                        }
                                        name="internationalExperience"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  {errorMsgDistributor.experience &&
                                    !experience && (
                                      <p style={{ color: "red" }}>
                                        {errorMsgDistributor.experience}
                                      </p>
                                    )}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Past Electric Vehicle retail experience
                                </label>
                                <ul>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      Yes
                                      <input
                                        value={experience}
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "experience",
                                            "Yes"
                                          )
                                        }
                                        name="experience"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  <li>
                                    <label className="Radio">
                                      {" "}
                                      No
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          handleDisributorCheck(
                                            e,
                                            "experience",
                                            "No"
                                          )
                                        }
                                        name="experience"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                  {errorMsgDistributor.experience &&
                                    !experience && (
                                      <p style={{ color: "red" }}>
                                        {errorMsgDistributor.experience}
                                      </p>
                                    )}
                                </ul>
                              </div>
                            </div>
                          )}

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Message</label>
                              <input
                                value={messegeDistributor}
                                name="messegeDistributor"
                                onChange={handleDistributorChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter ..."
                              />
                              {errorMsgDistributor.nameDistributor &&
                                !messegeDistributor && (
                                  <p style={{ color: "red" }}>
                                    {errorMsgDistributor.nameDistributor}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button onClick={handlseSubmitDistributor}>
                              Register Interest
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="PartnerBody"
              id="International"
              style={{ display: "none" }}
            >
              <article>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#Tabs3"
                    >
                      Booking / Test Ride Interest
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#Tabs4">
                      Distributor / Partner
                    </a>
                  </li>
                </ul>
              </article>
              <div className="tab-content">
                <div className="tab-pane active" id="Tabs3">
                  <div className="CommonForm">
                    <div className="row">
                      <div className="col-sm-8 offset-sm-2">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Model Name</label>
                              <select className="form-control">
                                <option>Select Model Name</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Model colour</label>
                              <select className="form-control">
                                <option>Select Model colour</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Full Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Full Name"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Country</label>
                              <select className="form-control">
                                <option>Select Country</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Email id</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Email id"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>City</label>
                              <select className="form-control">
                                <option>Select City</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Phone Number"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Message </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter ......"
                              />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="Tabs4">
                  <div className="CommonForm">
                    <div className="row">
                      <div className="col-sm-8 offset-sm-2">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Full Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Full Name"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Email id</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Email id"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Company name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Company name"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Country</label>
                              <select className="form-control">
                                <option>Select Country</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Experience in EV or Automobile Industry
                              </label>
                              <ul>
                                <li>
                                  <label className="Radio">
                                    {" "}
                                    0 Years
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                </li>
                                <li>
                                  <label className="Radio">
                                    {" "}
                                    0-5 Years
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                </li>
                                <li>
                                  <label className="Radio">
                                    {" "}
                                    5+ Years
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>City</label>
                              <select className="form-control">
                                <option>Select City</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Investment plan</label>
                              <ul>
                                <li>
                                  <label className="Radio">
                                    {" "}
                                    Under 1 M
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                </li>
                                <li>
                                  <label className="Radio">
                                    {" "}
                                    1 - 5 M
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                </li>
                                <li>
                                  <label className="Radio">
                                    {" "}
                                    5 M +
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Message </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter ......"
                              />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="PartnerRightShape">
            <img src="images/Shape-4.png" />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Ride;
