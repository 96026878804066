import axios from "axios";
import { baseUrl } from "../../config/config"
import { rideConstant, cityDropdownConstant } from "../actionTypes"



export const bookingInitiate=() =>({
    type: rideConstant.BOOKIN_INITIATE

})

export const bookingSuccess=(data) =>({
    type:rideConstant.BOOKIN_SUCCESS,
    payload:data

})

export const bookingFailure=(data) =>({
    type:rideConstant.BOOKIN_FAILURE,
    payload : data

})




export const BookingAction=(payload)=>{


    
        
    return dispatch => {
        dispatch(bookingInitiate())

        
        

        return new Promise((resolve, reject) =>
            axios.post(`${baseUrl}/website/booking-query`,payload,)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(bookingSuccess(data))

                    }
                    else {
                        dispatch(bookingFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(bookingFailure(err))
                    reject(err);
                })

        );
    }

}



export const modalColorInitiate=() =>({
    type: rideConstant.MODAL_NAME_INITIATE

})

export const modalColorSuccess=(data) =>({
    type:rideConstant.MODAL_NAME_SUCCESS,
    payload:data

})

export const modalColorFailure=(data) =>({
    type:rideConstant.MODAL_NAME_FAILURE,
    payload : data

})




export const ModalColorAction=(payload)=>{


    
        
    return dispatch => {
        dispatch(modalColorInitiate())

        return new Promise((resolve, reject) =>
            axios.post(`${baseUrl}/website/colour-list`,payload,)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(modalColorSuccess(data))

                    }
                    else {
                        dispatch(modalColorFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(modalColorFailure(err))
                    reject(err);
                })

        );
    }

}


export const cityDropdownInitiate=() =>({
    type: cityDropdownConstant.CITY_DROP_DOWN_INITIATE

})

export const cityDropdownSuccess=(data) =>({
    type:cityDropdownConstant.CITY_DROP_DOWN_SUCCESS,
    payload:data

})

export const cityDropdownFailure=(data) =>({
    type:cityDropdownConstant.CITY_DROP_DOWN_FAILURE,
    payload : data

})




export const CityDropDownAction=(payload)=>{


    
        
    return dispatch => {
        dispatch(cityDropdownInitiate())

        return new Promise((resolve, reject) =>
            axios.post(`${baseUrl}/admin/country-listing`,payload,)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(cityDropdownSuccess(data))

                    }
                    else {
                        dispatch(cityDropdownFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(cityDropdownFailure(err))
                    reject(err);
                })

        );
    }

}
