import React from "react";
import Logo from "../assets/images/Logo.png";
import "../App.css";

const Footer = () => {
  return (
    <footer>
      <div className="Footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="Foots">
                <img src={Logo}/>
                <p>
                  INDIA
                  {/* With lots of unique blocks, you can easily build a page
                  without coding. */}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="Foots">
                <h4>Follow Us</h4>
                <ul>
                  <li>
                    <a href="https://twitter.com/oneelectric1?s=21&t=yLcRVSWNLrO9Cb3-2G59JA" target="_blank">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com/one.electric.official?igshid=MmJiY2I4NDBkZg==" target="_blank">
                    <i className="fa fa-instagram"  />

                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@oneelectric4326" target="_blank">
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="Foots">
                <h4>Contact us</h4>
                <p><a href="mailto:contact@oneelectric.in" target="_blank" >contact@oneelectric.in</a></p>
                {/* <p>+133-394-3439-1435</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CopyRight">
        <p>
          <i className="fa fa-copyright" /> 2023 One Electric, All Rights
          Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
