import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { newsAction, newsIntrAction } from "../redux/actions/newsAction";
import moment from "moment/moment";
import Banner from "../assets/images/banner.jpg";
const News = () => {
  const dispatch = useDispatch();
  const { newsList, newsIntrList } = useSelector((state) => state.newsReducer);

  useEffect(() => {
    dispatch(newsAction());
    dispatch(newsIntrAction());
  }, []);

  return (
    <div>
      <Header />
      <section>
        <div className="Breadcumb Breadcumb2">
          <article>
            <h4>News</h4>
            {/* <p>
              Our residential, commercial, and fast charging stations are widely
              compatible, built on open standards & designed to be accessible
              for everyone. Our charging stations are safe and easy-to-use and
              let you hit the road fully charged, every time.
            </p> */}
          </article>
        </div>
      </section>

      <section>
        <div className="PartnerArea">
          <div className="PartnerLeftShape">
            <img src={require("../assets/images/Shape-2.png")} />
          </div>

          <div className="container-fluid">
            <div className="PartnerBody" id="India">
              <article>
                <ul className="nav nav-tabs NewsTabs">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#Tabs1">
                      India
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link  "
                      data-toggle="tab"
                      href="#Tabs2"
                    >
                      International
                    </a>
                  </li>
                
                </ul>
              </article>

              <div className="tab-content">
              <div className="tab-pane fade active show" id="Tabs1">
                  <div className="CommonForm-india">
                    <div className="row">
                      {newsList?.docs?.length > 0 &&
                        newsList?.docs?.map((data, i) => {
                          return (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                              <a href={data.link} target="_blank">
                                <div className="india-ride">
                                  <figure>
                                    <img src={data.image} alt="" />
                                  </figure>
                                  <figcaption>
                                    <h3>{data.title}</h3>
                                    <p>
                                      {moment(data.date).format("DD MMM 'YY")} &{" "}
                                      {moment(data.createdAt).format("HH:mm a")}
                                    </p>
                                    <span>
                                      <img src={data.newsLogo} />
                                    </span>
                                  </figcaption>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade " id="Tabs2">
                  <div className="CommonForm-india">
                    <div className="row">
                      {newsIntrList?.docs?.length > 0 &&
                        newsIntrList?.docs?.map((data, i) => {
                          return (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                              <a href={data.link} target="_blank">
                                <div className="india-ride">
                                  <figure>
                                    <img src={data.image} alt="" />
                                  </figure>
                                  <figcaption>
                                    <h3>{data.title}</h3>
                                    <p>
                                      {moment(data.date).format("DD MMM 'YY")} &{" "}
                                      {moment(data.createdAt).format("HH:mm a")}
                                    </p>
                                    <span>
                                      <img src={data.newsLogo} />
                                    </span>
                                  </figcaption>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
          </div>

          <div className="PartnerRightShape">
            <img src={require("../assets/images/Shape-4.png")} />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default News;
