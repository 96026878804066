import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import Logo from "../assets/images/aboutnew.jpeg";
import Logo1 from "../assets/images/about.jpeg";
import NewBike from "../assets/images/redBike.jpg";

const Aboutus = () => {
  return (
    <div>
      <Header />

      <section>
        <div className="Breadcumb">
          <article>
            <h4>About Us</h4>
            {/* <p>Our residential, commercial, and fast charging stations are widely compatible, 
                    built on open standards & designed to be accessible for everyone. 
                    Our charging stations are safe and easy-to-use and let you hit the road fully charged, every time.</p> */}
          </article>
        </div>
      </section>

      <section>
        <div className="PartnerArea">
          <div className="PartnerLeftShape">
            <img src={require("../assets/images/Shape-2.png")} />
          </div>

          <div className="container-fluid">
            <div className="about-body CommonForm">
              <div className="">
                <ul>
                  <aside className="order-2 order-lg-1">
                    <div className="about-left">
                      <h2>OUR MISSION</h2>
                      <p>
                        To create an electric transport revolution.We aim to do
                        this by using the highest quality components from India
                        which have a proven track record in the tough Indian
                        environment.
                        <br />
                        <br />
                        Extensive R&D in battery, motor and controller
                        technology combined with our experience of
                        running thousands of vehicles across 6 countries is
                        enabling us to build the perfect electric motorcycle for
                        city commute
                      </p>
                    </div>
                  </aside>
                  <figure className="order-1 order-lg-2">
                    <div className="about-right">
                      <img src={Logo1} />
                    </div>
                  </figure>
                </ul>

                <ul>
                  <figure>
                    <div className="about-right">
                      <img src={Logo} />
                    </div>
                  </figure>

                  <aside>
                    <div className="about-left missions">
                      <h2>Extraordinary Experiences</h2>
                      <h3>KRIDN (Sanskrit : To Play)</h3>
                      <p>
                        Smooth pickup, quick acceleration and power on demand
                        make this electric motorcycle the perfect vehicle for
                        gearless fun. <br />
                        From using wide tyres, heavy chassis and high grade
                        suspension suited to the tough Indian road conditions,
                        to combi-braking systems and easy charging capabilities,
                        we are proud to introduce an elite electric riding
                        experience.
                      </p>
                    </div>
                  </aside>
                </ul>
              </div>
            </div>
          </div>

          <div className="PartnerRightShape">
            <img src={require("../assets/images/Shape-4.png")} />
          </div>
        </div>
      </section>

      <section>
        <div className="PartnerArea">
          <div className="PartnerLeftShape">
            <img src={require("../assets/images/Shape-2.png")} />
          </div>
          <div className="container-fluid">
            <div className="PartnerBody">
              <div className="CommonForm">
                <div className="values">
                  <h2>Our Core Values</h2>
                  <ul>
                    <li>High-performance and Quality product</li>
                    <li>Safety first</li>
                    <li>Made In India</li>
                  </ul>
                  <br />
                  <p>
                    Using only the best high-quality components, we are
                    passionate about providing a long-lasting and premium
                    motorcycle. <br /> <br />
                    Combi-braking system, high-grade lighting from a leading OEM
                    and all safety measures in place for the battery and
                    electronics ensure our commitment towards safety. <br />{" "}
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Aboutus;
